const JWT_EXPIRES_AT_MILLI_SEC = 'jwt-expiresAt-milliSec';
const USER_JWT_EXPIRES_AT_MILLI_SEC = 'user-jwt-expiresAt-milli-sec';

const JWT = 'jwt';
const USER_JWT = 'user-jwt';

function saveToken(token) {
  const { accessToken, expiresIn, userId, scopes } = token;

  localStorage.setItem('userId', userId);
  localStorage.setItem(JWT, accessToken);

  const jwtExpiresAtMilliSec = Date.now() + expiresIn * 1000;
  localStorage.setItem(JWT_EXPIRES_AT_MILLI_SEC, jwtExpiresAtMilliSec);
  localStorage.setItem('scopes', scopes);
}

function saveUserToken(token) {
  const { accessToken, expiresIn, userId } = token;

  localStorage.setItem('userId', userId);
  localStorage.setItem(USER_JWT, accessToken);

  const jwtExpiresAtMilliSec = Date.now() + expiresIn * 1000;
  localStorage.setItem(USER_JWT_EXPIRES_AT_MILLI_SEC, jwtExpiresAtMilliSec);
}

function saveOrganizationId(organizationId) {
  localStorage.setItem('organizationId', organizationId);
}

function getOrganizationId() {
  return localStorage.getItem('organizationId');
}

function getJwt() {
  return localStorage.getItem(JWT);
}

function getUserJwt() {
  return localStorage.getItem(USER_JWT);
}

function isJwtValid() {
  if (!getJwt()) {
    return false;
  }

  const expiresAt = localStorage.getItem(JWT_EXPIRES_AT_MILLI_SEC);

  if (!expiresAt) {
    return false;
  }

  if (Date.now() > expiresAt) {
    return false;
  } 

  return true;
}

function getScopes() {
  const scopes = localStorage.getItem('scopes');
  if (!scopes) {
    return [];
  }
  return scopes
}

function clear() {
  localStorage.clear();
}

export default {
  saveToken,
  saveOrganizationId,
  getOrganizationId,
  getJwt,
  getScopes,
  isJwtValid,
  clear,
  saveUserToken,
  getUserJwt,
};
