import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import settings from "./store/settings";

Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/icons',
          name: 'icons',
          component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        // {
        //   path: '/profile',
        //   name: 'profile',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue'),
        //   meta: {
        //     requiresAuth: true,
        //   }
        // },
        {
          path: '/members',
          name: 'members',
          component: () => import(/* webpackChunkName: "demo" */ './views/Members.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        // {
        //   path: '/maps',
        //   name: 'maps',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Maps.vue')
        // },
        {
          path: '/connectors',
          name: 'connectors',
          component: () => import(/* webpackChunkName: "demo" */ './views/Connectors.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/posts',
          name: 'posts',
          component: () => import(/* webpackChunkName: "demo" */ './views/Posts.vue'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import(/* webpackChunkName: "demo" */ './views/Account.vue'),
          meta: {
            requiresAuth: true,
          }
        },
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
        {
          path: '/organizations',
          name: 'organizations',
          component: () => import(/* webpackChunkName: "demo" */ './views/Organizations.vue')
        },
        {
          path: '/register',
          name: 'register',
          component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!settings.isJwtValid()) {
      next({
        path: '/login'
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
