<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Ajacent"
      title="Ajacent"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />

        <!--<sidebar-item :link="{name: 'Icons', icon: 'ni ni-planet text-blue', path: '/icons'}"/>-->
        <!--<sidebar-item :link="{name: 'Maps', icon: 'ni ni-pin-3 text-orange', path: '/maps'}"/>-->
        <!--<sidebar-item :link="{name: 'User Profile', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"/>-->
        <sidebar-item v-show="showSidebar('member')" :link="{name: 'Members', icon: 'ni ni-key-25 text-info', path: '/members'}"/>
        <sidebar-item v-show="showSidebar('connector')" :link="{name: 'Connectors', icon: 'ni ni-bullet-list-67 text-red', path: '/connectors'}"/>
        <sidebar-item v-show="showSidebar('post')" :link="{name: 'Posts', icon: 'ni ni-bullet-list-67 text-red', path: '/posts'}"/>
        <sidebar-item :link="{name: 'Account', icon: 'ni ni-bullet-list-67 text-red', path: '/account'}"/>
        
        <sidebar-item :link="{name: 'Logout', icon: 'ni ni-key-25 text-info', path: '/login'}"/>

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import settings from "../store/settings";

  function makeSidebarShowList(scopes) {
    const showList = [];
    if (scopes.includes('me:post:write')) {
      showList.push('post');
    }

    if (scopes.includes('me:connector:write')) {
      showList.push('connector');
    }

    if (scopes.includes('me:member:write')) {
      showList.push('member');
    }

    return showList;
  }

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue', //vue|blue|orange|green|red|primary,
        sidebarShowList: [],
      };
    },
    mounted() {
      const scopes = settings.getScopes();
      this.sidebarShowList = makeSidebarShowList(scopes);
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      showSidebar(type) {
        return this.sidebarShowList.includes(type);
      },
    }
  };
</script>
<style lang="scss">
</style>
